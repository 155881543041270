import * as React from "react"
import { graphql } from 'gatsby'
import { Header, Masthead, TransitionMask } from '@components/organisms'
import { Grid, GridItem } from '@components/grid'
import { PageProps } from '@types'
import { lazy } from '@loadable/component'


const LazyInfo = lazy(() => import('@components/threedee/Info'))


const InfoPage = ({data: { 
  sanityPage: {
    description,
    gridItems,
    reflectionImage,
    mastheadImage,
    mobileMastheadImage,
    modelPosition,
    modelPositionMobile, 
  }
}}: PageProps) => (
  <>
    <Header />
    {reflectionImage && mastheadImage && (
      <Masthead {...{ reflectionImage, mastheadImage, mobileMastheadImage }} >
        <LazyInfo {...{ modelPosition, modelPositionMobile }} />
      </Masthead>
    )}
    <main>
      <Grid>
        {gridItems.filter(p => !!p).map((item) => (
          <GridItem key={item._key} {...item} />
        ))}
      </Grid>
    </main>
    <TransitionMask />
  </>
)




export const query = graphql`
  query InfoPageQuery {
    sanityPage(slug: {current: {eq: "info"}}) {
      ...pageFields
    }
  }
`



export default InfoPage